<template>
    <main class="container mt-5 py-2">
        <div class="row justify-content-center mb-5">
            <div style="width: 500px;">
                <div class="card-register-2 p-2">
                    <div class="d-flex justify-content-center">
                        <img src="/images/logo.jpg" alt="" width="200" style="display: block; margin: auto;" />
                    </div>
                    <div class="mt-2">
                        <p class="parrafo-register">¡Activa tu cuenta!</p>
                    </div>
                    <div class="mt-2 mb-2 row nunito font-16 color-080">
                        <div class="col"></div>
                        <div class="col-9 text-center">
                            Ingresa el código de verificación<br/>
                            enviado a tu celular
                        </div>
                        <div class="col"></div>
                    </div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row justify-content-center">
                                <div class="col"></div>
                                <div class="col-10">
                                    <input
                                        type="text"
                                        class="form-control border"
                                        placeholder="Código de verificación"
                                        maxlength="6"
                                        v-model="codigoInput"
                                    />
                                </div>
                                <div class="col"></div>
                            </div>                 
                            <div class="form-group row justify-content-center mt-3">
                                <div class="col"></div>
                                <div class="col-10 nunito font-13 color-844 text-center">
                                    ¿No recibiste el código? Puedes recibirlo {{intentos}} veces más
                                </div>
                                <div class="col"></div>
                            </div>                 
                            <div class="form-group row justify-content-center mt-4 mb-4 pb-1">
                                <div class="col-5 text-center">                                   
                                    <template v-if='habilitar == 1 && intentos >= 1'>
                                        <input
                                            @click="reenviarCodigo"
                                            type="button"
                                            value="Reenviar SMS"
                                            class="btn btn-secundario"
                                            style="width:100%"
                                        />
                                    </template>
                                    <template v-else>
                                        <input
                                            type="button"
                                            value="Reenviar SMS"
                                            class="btn btn-disabled"
                                            style="width:100%"
                                        /> 
                                    </template>
                                </div>
                                <div class="col-1 text-center pt-2">ó</div>
                                <div class="col-5 text-center">                                   
                                    <template v-if='habilitar == 1 && intentos >= 1'>
                                        <input
                                            @click="reenviarCodigoTTS"
                                            type="button"
                                            value="Llámame"
                                            class="btn btn-secundario"
                                            style="width:100%"
                                        />
                                    </template>
                                    <template v-else>
                                        <input
                                            type="button"
                                            value="Llámame"
                                            class="btn btn-disabled"
                                            style="width:100%"
                                        /> 
                                    </template>
                                </div>
                            </div>      
                            <div class="form-group row justify-content-center nunito mt-4 mb-1" v-if='habilitar == 0 && intentos >= 1'>
                                <div class="col-11 text-center font-18 background-9fa pt-3 round-8" style="height: 110px;">                                    
                                    El código de verificación vence en <span class="color-4d3 font-weight-bold"><span id="timer"></span> segundos</span>
                                    <div class="text-center mt-2 mb-3 color-4d3">
                                        <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                                            <span class="sr-only">Cargando...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                            <div class="form-group row justify-content-center nunito mt-3 mb-1" v-if='habilitar == 0 && intentos == 0'>
                                <div class="col-11 text-center font-17 background-9fa pt-3 round-8" style="height: 110px;">                                    
                                    Ingresa el último código enviado antes de <span class="color-4d3 font-weight-bold"><span id="timer"></span> minutos</span>
                                    <br/>
                                    <div class="text-center mt-2 mb-3 color-4d3">
                                        <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
                                            <span class="sr-only">Cargando...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import axios from 'axios';
//import { fireToast } from '../assets/toast';
import swal from 'sweetalert2';

export default {
    components: {},
    data() {
        return {
            m: 1,
            s: 60,
            sh: "00",
            intentos: 2,
            habilitar: 0,
            token: this.$route.query.pk_token,
            codigoInput: ''
        };
    },
    watch: {
        codigoInput(value) {
            if(value.length == 6) {
                this.verificarCodigo(value);
            }
        }
    },
    mounted() {
        this.initime();
    },
    methods: {
        initime() {
            document.getElementById('timer').innerHTML = this.m + ":" + this.sh;
            this.startTimer();
        },
        startTimer() {

            if(this.s >= 0)
                this.s = this.s - 1;

            this.sh = this.s;

            if(this.s < 10 && this.s >= 0)
                this.sh = "0"+this.s;

            if(this.s < 0) this.s = 59;

            if(this.s == 59){this.m = this.m - 1}

            if(this.m < 0){ 
                console.log('timer completed') 
                this.s = 0;
                this.m = 0;
                this.habilitar = 1;

                if(this.intentos == 0) {                    
                    this.habilitar = 1;
                    //envia el token para deshabilitar el usuario de envios de codigo
                    axios
                    .post(process.env.VUE_APP_URL_API+'/desactivar', {
                        token: this.token,
                    }).then(()=>{
                        this.token = null;
                    });                
                    swal.fire({
                        title: '<div class="nunito color-844">Los intentos han finalizado, serás redirigido nuevamente al formulario de creación de cuenta.</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                }
            } else {
                if(document.getElementById('timer')) {
                    document.getElementById('timer').innerHTML = this.m + ":" + this.sh;  
                }  
                setTimeout(this.startTimer, 1000); 
                //setTimeout(this.startTimer, 5000);
            }             
        },       
        /**
         * Reenviar nuevo Codigo
         */
        reenviarCodigo() {

            let alert = swal.fire({
                title: '<div class="nunito color-844">Enviando código nuevamente...</div>',
                allowEscapeKey: false,
                allowOutsideClick: false,
                onOpen: () => {
                    swal.showLoading();
                },
            });            

            axios
            .post(process.env.VUE_APP_URL_API+'/reenviarcodigo', {
                token: this.token,
            })
            .then((res) => {
                console.log(res);
                alert.close();

                if(res.data.code == 1){
                    swal.fire({
                        title: '<div class="nunito color-844">Código enviado</div>',
                        text: 'Hemos enviado un nuevo código de verificación a tu número de celular',
                        icon: 'success'
                    });
                    this.m = 1;
                    this.s = 60;
                    this.sh = "00";
                    this.habilitar = 0;
                    this.token = res.data.token;
                    //this.$router.push({path:'/validar', query:{ pk_token: res.data.token }});       

                    this.intentos = res.data.intento;
                    setTimeout(this.startTimer, 250);
                } 

                if(res.data.code == 2){
                    this.habilitar = 0;
                    this.token = null;
                    swal.fire({
                        title: '<div class="nunito color-844">Húbo un error</div>',
                        text: 'No se logro enviar el código contacte con soporte.',
                        icon: 'error'
                    });
                }

                if(res.data.code == 3){
                    this.habilitar = 0;
                    this.token = null;
                    swal.fire({
                        title: '<div class="nunito color-844">El codigo fue vencido, serás redirigido nuevamente al formulario de creación de cuenta.</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                }
                
                if(res.data.code == 4){
                    this.habilitar = 0;
                    this.token = null;
                    axios
                    .post(process.env.VUE_APP_URL_API+'/desactivar', {
                        token: this.token,
                    });
                    swal.fire({
                        title: '<div class="nunito color-844">El codigo fue vencido, serás redirigido nuevamente al formulario de creación de cuenta.</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                }
            })
            .catch((err) => {
                alert.close();
                console.log(err);
                swal.fire({
                    title: '<div class="nunito color-844">Ha ocurrido un error</div>',
                    text: 'Ha ocurrido un error al realizar el envio de su código intenta de nuevo.',
                    icon: 'danger',
                    onOpen: () => {},
                });
            }); 
        },
        reenviarCodigoTTS() {

            let alert = swal.fire({
                title: '<div class="nunito color-844">Enviando código nuevamente...</div>',
                allowEscapeKey: false,
                allowOutsideClick: false,
                onOpen: () => {
                    swal.showLoading();
                },
            });            

            axios
            .post(process.env.VUE_APP_URL_API+'/reenviarcodigotts', {
                token: this.token,
            })
            .then((res) => {
                console.log(res);
                alert.close();

                if(res.data.code == 1){
                    swal.fire({
                        title: '<div class="nunito color-844">Código enviado</div>',
                        text: 'En un momento recibirás una llamada con un nuevo código de verificación',
                        icon: 'success'
                    });
                    this.m = 1;
                    this.s = 60;
                    this.sh = "00";
                    this.habilitar = 0;
                    this.token = res.data.token;
                    //this.$router.push({path:'/validar', query:{ pk_token: res.data.token }});       

                    this.intentos = res.data.intento;
                    setTimeout(this.startTimer, 250);
                } 

                if(res.data.code == 2){
                    this.habilitar = 0;
                    this.token = null;
                    swal.fire({
                        title: '<div class="nunito color-844">Húbo un error</div>',
                        text: 'No se logro enviar el código contacte con soporte.',
                        icon: 'error'
                    });
                }

                if(res.data.code == 3){
                    this.habilitar = 0;
                    this.token = null;
                    swal.fire({
                        title: '<div class="nunito color-844">El codigo fue vencido, serás redirigido nuevamente al formulario de creación de cuenta.</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                }
                
                if(res.data.code == 4){
                    this.habilitar = 0;
                    this.token = null;
                    axios
                    .post(process.env.VUE_APP_URL_API+'/desactivar', {
                        token: this.token,
                    });
                    swal.fire({
                        title: '<div class="nunito color-844">El codigo fue vencido, serás redirigido nuevamente al formulario de creación de cuenta.</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                }
            })
            .catch((err) => {
                alert.close();
                console.log(err);
                swal.fire({
                    title: '<div class="nunito color-844">Ha ocurrido un error</div>',
                    text: 'Ha ocurrido un error al realizar el envio de su código intenta de nuevo.',
                    icon: 'danger',
                    onOpen: () => {},
                });
            }); 
        },
        verificarCodigo(codigo) { 

            let alert = swal.fire({
                title: '<div class="nunito color-844">Estamos validando el código ingresado</div>',
                allowEscapeKey: false,
                allowOutsideClick: false,
                onOpen: () => {
                    swal.showLoading();
                },
            });

            axios
            .post(process.env.VUE_APP_URL_API+'/activate-codigo', {
                token: this.token,
                codigo: codigo
            })
            .then((res) => {
                console.log('activate-codigo', res.data);
                alert.close();   
                if (res.data.code == 1) { 
                    swal.fire({
                        title: "<div class='nunito color-844'>¡Cuenta activada!</div>",
                        text: "Te estamos redirigiendo a nuestro portal",
                        icon: "success", 
                        timer: 2000,
                        onClose: () => {
                            window.open(res.data.response.link, "_self");
                        },
                    });   
                } else if (res.data.code == 2) {
                    this.codigoInput = ''; 
                    swal.fire({
                        title: '<div class="nunito color-844">Código inválido, vuelve a intentarlo</div>',
                        icon: "warning", 
                        timer: 6000                               
                    });   
                } else if (res.data.code == 3) {
                    this.codigoInput = ''; 
                    swal.fire({
                        title: '<div class="nunito color-844">El código de verificación ha vencido, vuelve a intentarlo</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                } else if (res.data.error == 4) {
                    this.codigoInput = ''; 
                    swal.fire({
                        title: '<div class="nunito color-844">El código de verificación ha vencido, vuelve a intentarlo</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                }     
                else if (res.data.error == -1) {
                    this.codigoInput = ''; 
                    swal.fire({
                        title: '<div class="nunito color-844">Hubo un error por nuestra parte, contáctese con soporte</div>',
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(() => {            
                        this.$router.push({path:'/'});            
                    });
                }  
            })
            .catch((err) => {
                alert.close();
                console.log(err);
                swal.fire({
                    title: '<div class="nunito color-844">Ha ocurrido un error</div>',
                    text: 'Contactese con soporte, sera un gusto ayudarlo',
                    icon: 'danger',
                    onOpen: () => {},
                });
            });            
        }
    }
}

</script>

<style>
.btn-secundario, .btn-disabled {
    padding: 6px 0px!important;
}
</style>